import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const Help = () => (
  <React.Fragment>
  <Box component="main" style={{flexGrow: 1, overflow: 'auto', fontSize: '20px', lineHeight: '34px'}} bgcolor="background.paper">
    <Box p={1}>
      <Box>
        <Typography variant="h4">Hot 100 Form Instructions</Typography>
        <ol>
          <li>Create your shortlist
              <ul>
                  <li>Work through the list of eligible tracks and create a shortlist before submitting your final vote. Selecting the &lsquo;heart&rsquo; symbol next to a track adds it to your shortlist.</li>
                  <li>You can search the list in a number of different ways: you can use the search bar and type a track&rsquo;s name in, browse the list in a random order, or by region, or alphabetically by song or artist.</li>
                  <li>Can&rsquo;t find a track? If a track was released between November 1, 2023 and October 31 2024, you can still vote for it by hitting the &lsquo;+&rsquo; button and filling out the track details. Please note you cannot use this feature to vote for the same track multiple times.</li>
              </ul>
          </li>
          <li>Save your shortlist
              <ul>
                  <li>4ZZZ Subscribers have the ability to save their shortlist and come back to it later by using the Subscriber Shortlist Feature on the &lsquo;Shortlist&rsquo; page. Not a subscriber but want to use this feature? Subscribe online now.&nbsp;
                      <ul>
                          <li><strong>Saving:</strong> On the &lsquo;Shortlist&rsquo; page, hit the &lsquo;Save&rsquo; icon in the bottom right of the screen, enter your subscriber number and matching email (if you&rsquo;re unsure of what email it is, check which
                              account Zedletter is sent to) and hit &lsquo;Save&rsquo; to save your progress.&nbsp;</li>
                          <li><strong>Restoring</strong>: To come back to your shortlist after saving, return to the &lsquo;Shortlist&rsquo; page, hit the &lsquo;Save&rsquo; icon and enter your details again to restore. If you start adding new tracks without first restoring your shortlist, the new tracks will override the existing ones when you go to save again. The
                              point is - always restore first!</li>
                      </ul>
                  </li>
              </ul>
          </li>
          <li>Make your vote before Midnight, Boxing Day
              <ul>
                  <li>Whether you only had 1 song that you deem worthy of entry in the Hot 100 or 50, your votes will be assigned numerical value through our weighted voting system. We are allowing voters the ultimate freedom in the Hot 100. You can select as many
                      songs as you deem worthy in your list, we recommend voting for an even 10 tracks for the optimal use of your votes.&nbsp;</li>
                  <li>Choose whether to rank your songs in order of most to least favourite, or leave them un-ranked to be assigned equal value
                  </li>
                  <li>Voters can choose their own selections that do not appear on the list by hitting the &lsquo;+&rsquo; symbol, however you cannot use this to submit a second vote for a track you have already selected from the list. </li>
                  <li>Strictly one vote per person</li>
              </ul>
          </li>
          <li>Complete the short listener survey- &nbsp;it&rsquo;s optional, but why not use this as an opportunity to tell us what you really think?&nbsp;</li>
          <li>Submit your vote &amp; tell the world!
              <ul>
                  <li>This year your votes will be displayed as a graphic featuring the Hot 100 artwork by <a style={{ color: 'white' }} href="https://www.instagram.com/skysaphron" target="_blank">@skysaphron</a>, right click on the graphic to save the image and share it on social media using the hashtag #4ZZZHot100</li>
              </ul>
          </li>
      </ol>

      {/* <Typography variant="h4">How are my votes distributed?</Typography>

      <p><strong>Ranked</strong></p>
      <p>If you choose to rank yours songs in order from your most to least favourite, each track in your list will be assigned points that decrease in value as your list goes on. The scale at which they decrease depends on how many tracks you choose. The system
          is optimised for a voting list of 10 tracks, which gives you a maximum of 55 points to work with no matter how many songs end up in your final vote.&nbsp;</p>
      <p>The maximum of 55 points is the sum of points distributed across 10 tracks. For example, your favourite track is worth 10 points, your second favourite receives 9 points, your third 8 points and so on until you reach your tenth track, worth 1 point. The
          sum of all of the points assigned to 10 tracks using the ranked method is 10+9+8+7+6+5....+1 = 55. Using the ranked method, the maximum number of points any <em>one song&nbsp;</em>can receive is 10.</p>
      <p>If you choose less than 10 songs, we will assign 10 points to your first song, 9 to your second choice and so on until your list stops. For example, if you choose only 5 songs, your total points will be the sum of 10+9+8+7+6, distributed on a curve from
          your first choice (worth 10 points) to your last choice (worth 6 points).&nbsp;</p>
      <p>If you select more than 10 songs, we will scale the maximum points pool of 55 so that each of your selections is assigned a value based on where it sits in your list.&nbsp;</p>
      <p><strong>Un-ranked</strong></p>
      <p>If you choose not to rank your songs, your points will be averaged across your total selection. This method is also optimised for a vote of 10 tracks and uses 55 as the maximum number of points any one voter can assign to their total list.</p>
      <p>If your list consists of 10 songs, each track will be worth 5.5 points. Using the un-ranked method, the maximum number of points any <em>one song&nbsp;</em>can receive is 5.5.</p>
      <p>If your list is less than 10 songs, each song will be worth 5.5 points.&nbsp;</p>
      <p>If you choose more than 10 songs, each track will be worth 55 divided by the total number of songs in your list.</p> */}

    </Box>
  </Box>
</Box>
<Box component="footer"></Box>
</React.Fragment>
)

export default Help
