import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import ListAltIcon from '@material-ui/icons/ListAlt';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const Header = (props) => (
  <AppBar position="relative">
    <Toolbar>

    <img alt="4ZZZ HOT 100" style={{ height: '48px', width: 'auto', paddingRight: '10px', lineHeight: '48px' }} src={process.env.PUBLIC_URL + '/hot100withfriend.png'} />
      <Typography style={{flexGrow: 1}} variant="h6" color="inherit" noWrap>
        {/* HOT 100 */}
      </Typography>
	    <Tabs
        value={props.location.pathname}
        indicatorColor="primary"
        textColor="primary"
        aria-label="icon tabs example"
      >
        <Tab value="/" component={Link} to="/" icon={<Tooltip title="Full Songlist" aria-label="add"><ListAltIcon /></Tooltip>} aria-label="songs" />
        <Tab value="/help" component={Link} to="/help" icon={<Tooltip title="Instructions" aria-label="add"><HelpOutlineIcon /></Tooltip>} aria-label="help" />
        <Tab value="/shortlist" component={Link} to="/shortlist" icon={props.favorites.length ? <Tooltip title="Shortlist" aria-label="add"><Badge badgeContent={props.favorites.length } color="secondary" ><Favorite /></Badge></Tooltip> : <Tooltip title="Shortlist" aria-label="add"><FavoriteBorder /></Tooltip>} aria-label="favorite" />
      </Tabs>
      <img alt="Heart" style={{ height: 'auto', width: '48px', paddingLeft: '10px', lineHeight: '48px' }} src={process.env.PUBLIC_URL + '/Heartleft.png'} />
    </Toolbar>
  </AppBar>
)

export default connect( state => ({ favorites: state.favorites }) ) (Header)
