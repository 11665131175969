import React from 'react';
import './App.css';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Body from './components/Body.js';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux'

import { pink, orange, lightBlue, purple } from '@material-ui/core/colors';

import configureStore from './configureStore'
import Div100vh from 'react-div-100vh'

import {
  BrowserRouter as Router,
} from "react-router-dom";

const reduxStore = configureStore()

const App = () => {

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#BEE65F',
      },
      secondary: {
        main: '#2192D9',
      },
      type: 'dark',
			background: {
				paper: '#333366',
	    },
			text: {
				primary: '#FCF8CC',
			}
    },
    overrides: {
      // Style sheet name ⚛️
      MuiTab: {
        root: {
          minWidth: 0,
         '@media (min-width: 0px)': {
           minWidth: 0
         }
        }
      },
      MuiAppBar: {
        // Name of the rule
        colorPrimary: {
          // Some CSS
          backgroundColor: '#A878F8',
        },
      },
    },
  });

  return (
    <Provider store={reduxStore}>
    <Router>
      <ThemeProvider theme={theme}>
        <Div100vh style={{ minHeight: '100rvh', display: 'flex',
        flexDirection: 'column'}}>
          <CssBaseline />
          <Body />
        </Div100vh>
      </ThemeProvider>
    </Router>
    </Provider>
  )
}
export default App;
